var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbr-workout--page"},[_c('main-app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.$attrs.id)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedWorkout.title)+" ")]):_c('span',[_vm._v(" Add New Workout ")])]},proxy:true},{key:"top-actions",fn:function(){return [(_vm.canBeDuplicated)?_c('v-btn',{staticClass:"mr-auto bg-primary-gradient primary px-10",attrs:{"loading":_vm.isDuplicatingWorkout},on:{"click":_vm.deplicateWorkout}},[_vm._v(" Duplicate Workout "),_c('v-icon',{staticClass:"ml-3 small"},[_vm._v(" "+_vm._s(_vm.icons.duplicate)+" ")])],1):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('arrow-button',{attrs:{"done":_vm.stage > 1,"to":{
          name: _vm.isCompleted ? 'workout.details' : 'new.workout.details',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","replace":""}},[_vm._v(" Workout Details ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 3,"to":{
          name: _vm.isCompleted ? 'workout.exercises' : 'new.workout.exercises',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":"","replace":""}},[_vm._v(" Workout Exercises ")])]},proxy:true}])}),_c('div',{staticClass:"mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1),_c('v-overlay',{staticClass:"text-center",attrs:{"value":_vm.isDuplicatingWorkout,"opacity":0.7,"color":"white"}},[_c('v-progress-circular',{attrs:{"size":60,"width":3,"color":"primary","indeterminate":""}}),_c('h1',{staticClass:"primary--text mt-10"},[_vm._v("Duplicating Workout")])],1),_c('v-snackbar',{attrs:{"timeout":"3000","bottom":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"green","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.duplicate),callback:function ($$v) {_vm.duplicate=$$v},expression:"duplicate"}},[_vm._v(" Workout has been duplicated ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }