<template>
  <div class="bbr-workout--page">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id">
          {{ selectedWorkout.title }}
        </span>
        <span v-else> Add New Workout </span>
      </template>

      <template v-slot:top-actions>
        <v-btn
          v-if="canBeDuplicated"
          class="mr-auto bg-primary-gradient primary px-10"
          @click="deplicateWorkout"
          :loading="isDuplicatingWorkout"
        >
          Duplicate Workout

          <v-icon class="ml-3 small"> {{ icons.duplicate }} </v-icon>
        </v-btn>
      </template>

      <template v-slot:actions>
        <arrow-button
          :done="stage > 1"
          :to="{
            name: isCompleted ? 'workout.details' : 'new.workout.details',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          replace
        >
          Workout Details
        </arrow-button>

        <arrow-button
          :done="stage > 3"
          :to="{
            name: isCompleted ? 'workout.exercises' : 'new.workout.exercises',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
          replace
        >
          Workout Exercises
        </arrow-button>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>

      <v-overlay
        class="text-center"
        :value="isDuplicatingWorkout"
        :opacity="0.7"
        color="white"
      >
        <v-progress-circular
          :size="60"
          :width="3"
          color="primary"
          indeterminate
        />

        <h1 class="primary--text mt-10">Duplicating Workout</h1>
      </v-overlay>

      <v-snackbar v-model="duplicate" timeout="3000" bottom right>
        Workout has been duplicated
        <template v-slot:action="{ attrs }">
          <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiContentDuplicate } from '@mdi/js'

export default {
  name: 'NewWorkout',

  components: {
    MainAppBar,
    ArrowButton,
  },

  data() {
    return {
      loading: false,
      duplicate: false,
      isDuplicatingWorkout: false,
      icons: {
        duplicate: mdiContentDuplicate,
      },
    }
  },

  computed: {
    ...mapState({
      selectedWorkout: (state) => state.workouts.selectedWorkout,
    }),

    next() {
      return Boolean(this.$attrs.id || false)
    },

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },

    isCompleted() {
      return !!this.selectedWorkout.completed_at
    },

    canBeDuplicated() {
      return !!this.$attrs.id
    },
  },

  methods: {
    ...mapActions({
      getWorkout: 'workouts/getWorkout',
      getWorkoutCircuits: 'workouts/getWorkoutCircuits',
      getDuplicateWorkout: 'workouts/duplicateWorkout',
    }),

    ...mapMutations({
      setSelectedWorkout: 'workouts/setSelectedWorkout',
      clearSelectedWorkout: 'workouts/clearSelectedWorkout',
    }),

    async initialize(workoutId) {
      await this.fetchWorkout(workoutId)
      await this.fetchWorkoutCircuits(workoutId)
    },

    async fetchWorkout(workoutId) {
      await this.getWorkout(workoutId)
    },

    async fetchWorkoutCircuits(workoutId) {
      await this.getWorkoutCircuits(workoutId)
    },

    deplicateWorkout() {
      this.isDuplicatingWorkout = true

      this.getDuplicateWorkout(this.$attrs.id).then((workout) => {
        setTimeout(() => {
          this.isDuplicatingWorkout = false

          this.setSelectedWorkout(workout)

          this.duplicate = true

          this.$router.replace({
            name: 'new.workout',
            params: { id: `${workout.id}` },
            query: { duplicate: this.duplicate },
          })
        }, 800)
      })
    },
  },

  beforeRouteEnter(to, from, next) {
    next((ctx) => {
      let workoutId = Number(ctx.$attrs.id)

      if (!workoutId) return

      ctx.initialize(workoutId)
    })
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query.duplicate) {
      this.duplicate = true
    }

    next()
  },

  beforeRouteLeave(to, from, next) {
    this.clearSelectedWorkout()

    next()
  },
}
</script>
